(function() {
  function vendorModule(_export) {
    'use strict';
    return {
      execute() {
        _export(require('react-dom'));
      },
    };
  }
  System.register(['react'], vendorModule);
})();